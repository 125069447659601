const translations = {
  days: {
    en: "DAYS",
    es: "DÍAS",
  },
  hours: {
    en: "HOURS",
    es: "HORAS",
  },
  minutes: {
    en: "MINUTES",
    es: "MINUTOS",
  },
  seconds: {
    en: "SECONDS",
    es: "SEGUNDOS",
  },
  last_places_available: {
    en: "Last places available",
    es: "Últimas plazas disponibles",
  },
}

export default translations;
