<script>
    import {createEventDispatcher} from "svelte";
    import axios from "axios";
    import ChatTextBox from "./chat/ChatTextBox.svelte";
    import ChatBubbleBot from "./chat/ChatBubbleBot.svelte";
    import ChatBubbleUser from "./chat/ChatBubbleUser.svelte";
    import NewSingleFaq from './NewSingleFaq.svelte';
    import Button from './base/Button.svelte';
    import Closer from './base/Closer.svelte';
    import { getHost } from "../utils/sendwhatsapp";

    export let data;
    export let activePerson;
    export let faqlevel;

    let chatList = [{id: 1, botText: data.faqsChatBotQuestionText ?? 'Hello, how can I help you?' , userText: ""},];
    let faqs = [];
    let isSolved = false;

    $: fullScreenMobile = data.expandFullScreenMobile;

    const dispatch = createEventDispatcher();

    async function  setVal(v){
        let objDiv = document.getElementById("messageBox");
        objDiv.scrollTop = objDiv.scrollHeight;
        if(v.detail.trim() == '')
            return;
        let text = v.detail;
        let param = {
            search: v.detail.trim(),
        };
        if(faqlevel === "business"){
            param["business"] = data.id;
        }else if(faqlevel === "whatsapp"){
            param["whatsapp"] = activePerson.id;
        }
        await axios
        .post(getHost() + "/api/searchfaqs", param)
        .then((response) => {
          console.log("old")
            faqs = response.data;
            if(faqs.length > 0){
                isSolved = true;
            }else{
                isSolved = false;
            }
        })
        .catch((error) => {
            //console.log(error);
        });

        chatList[chatList.length - 1].userText =  text;
        chatList.push({
            id: chatList.length + 1,
            botText: isSolved ? "": (data.faqsChatBotNotSolvedText ?? "Sorry, I don't have an answer for that, what else can I help you with?"),
            userText: "" });

        setTimeout(() => {
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 300);
        objDiv.scrollTop = objDiv.scrollHeight;
    }

    function bottomBorder(i){
      return i < faqs.length - 1?"bottom-border":"";
    }
</script>

<div class="chatwith-chat chatwith-faqschat" class:fullScreenMobile>
    <div  class="chatwith-chat-panel">
        <div class="chatwith-chat-content">
            {#if data.widgetType !== 'bigperson'}
            <Closer on:close={() => dispatch('problem-solved')} />
            {/if}
            <div  class="chatwith-chat-messages" id="messageBox">
                {#each chatList as element, i (element.id)}
                <div class="scale-in-left">
                    {#if element.botText !== ""}
                    <ChatBubbleBot text={element.botText} showDot={true}/>
                    {/if}
                </div>
                {#if element.userText !== ''}
                <div class="scale-in-right">
                    <ChatBubbleUser bubbleData={{val:element.userText}} />
                </div>
                {/if}
                {/each}
            </div>
            <div class="chatwith-faqschat-faqs" style="display:{isSolved?"block":"none"};">
                {#each faqs as faq, i}
                <div class={bottomBorder(i)}>
                    <NewSingleFaq activeFaq={true} faq={faq} allowToggle={true}/>
                </div>
                {/each}
            </div>
            {#if chatList.length > 1}
            <div class="chatwith-faqschat-buttons">
                {#if activePerson.online === true}
                <Button
                    on:click={() => dispatch('problem-not-solved')}
                    buttonText="WhatsApp"
                    showIcon={true}
                />
                {:else}
                <Button
                    on:click={() => dispatch('leave-message')}
                    buttonText={data.leaveMessage ?? "Leave a message"}
                    showIcon={false}
                />
                {/if}
            </div>
            {/if}
            <div class="chatwith-chat-footer">
                <ChatTextBox
                    on:message={setVal}
                    hasBorder={data.widgetType === "bigperson"}
                    fullScreenMobile={data.expandFullScreenMobile}
                    translateChatAnswer={data.translateChatAnswer}
                />
            </div>
        </div>
    </div>
</div>
