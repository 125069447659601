<script>
  import FormValidate from './FormValidate.svelte'
  import StripeField from './base/StripeField.svelte'
  import Closer from './base/Closer.svelte'
  import { createEventDispatcher } from 'svelte'
  import { sendBooking } from '../utils/sendwhatsapp';

  const dispatch = createEventDispatcher()

  export let data
  export let person
  export let noclose
  export let bookingEvent

  $: onboardPaymentLink = data.onboardPaymentLinks.filter(
    (obj) => obj.link === person.id,
  )[0]

  let errorList = []
  let formValidateList = []
  let isSuccess = false
  let stripeField
  let name
  let phoneNumber
  let address
  let email

  const pay = () => {
    handleSuccess()
    for (let i in formValidateList)
      try {
        formValidateList[i].validate()
      } catch (ex) {
        //console.log(ex)
      }
    if (errorList.length == 0) {
      stripeField.submit()
    }
  }

  const toFixed = (num) => {
    return parseFloat(num).toFixed(2)
  }

  const handleSuccess = () => {
    // isSuccess = true
    //console.log(bookingEvent)
    if (person?.bookingConfigs?.length > 0 && bookingEvent) {
      sendBooking(person, bookingEvent)
    }
  }
</script>

<div class="chatwith-form chatwith-payment">
  {#if !noclose}
    <Closer on:close={() => dispatch('close')} />
    {#if onboardPaymentLink.image}
      <div class="chatwith-image">
        <img alt="imageFile" src={onboardPaymentLink.image} />
      </div>
    {/if}
    {#if onboardPaymentLink.name}
      <div class="chatwith-title">{onboardPaymentLink.name}</div>
    {/if}
  {/if}
  {#if onboardPaymentLink.Description}
    <div class="chatwith-description">
      {@html onboardPaymentLink.Description}
    </div>
  {/if}
  <div class="chatwith-price">
    {toFixed(onboardPaymentLink.price)} {onboardPaymentLink.currency}
  </div>

  {#if onboardPaymentLink.requireName == true}
    <div class="chatwith-form-element">
      <input bind:value={name} type="text" placeholder="Name" />
    </div>
    <FormValidate
      bind:value={errorList}
      bind:this={formValidateList[0]}
      item={{ type: 'text', val: name, telNum: '', required: true }}
      {data}
      index={0} />
  {/if}
  {#if onboardPaymentLink.requirePhoneNumber == true}
    <div class="chatwith-form-element">
      <input bind:value={phoneNumber} type="text" placeholder="Phone number" />
    </div>
    <FormValidate
      bind:value={errorList}
      bind:this={formValidateList[1]}
      item={{ type: 'tel', val: phoneNumber, telNum: phoneNumber, required: true }}
      {data}
      index={1} />
  {/if}
  {#if onboardPaymentLink.requireAddress == true}
    <div class="chatwith-form-element">
      <input bind:value={address} type="text" placeholder="Address" />
    </div>
    <FormValidate
      bind:value={errorList}
      bind:this={formValidateList[2]}
      item={{ type: 'text', val: address, telNum: '', required: true }}
      {data}
      index={2} />
  {/if}
  {#if onboardPaymentLink.requireEmail == true}
    <div class="chatwith-form-element">
      <input bind:value={email} type="email" placeholder="Email" />
    </div>
    <FormValidate
      bind:value={errorList}
      bind:this={formValidateList[3]}
      item={{ type: 'email', val: email, telNum: '', required: true }}
      {data}
      index={3} />
  {/if}
  <div class="chatwith-form-element">
    <StripeField
      {onboardPaymentLink}
      bgcolor={data.color}
      bind:this={stripeField}
      on:success={handleSuccess}
      {name}
      {email}
      {phoneNumber}
      {address} />
  </div>

  <div>
    {#if !isSuccess}
      <button on:click={pay}>
        Pay {toFixed(onboardPaymentLink.price)} {onboardPaymentLink.currency} -
        Stripe
      </button>
    {/if}
  </div>
  {#if onboardPaymentLink.usePaypal}
    <div class="chatwith-paypal-element">
      <a href={onboardPaymentLink.payLink} target="_blank">
        Pay {toFixed(onboardPaymentLink.price)} {onboardPaymentLink.currency} -
        Paypal
      </a>
    </div>
  {/if}
</div>
