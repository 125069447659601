<script>
  import { createEventDispatcher } from "svelte";
  import axios from "axios";
  import { onMount } from "svelte";
  import { getHost } from "../../utils/sendwhatsapp";
  import { currentLanguage } from '../../store'
  import translations from '../../translations'

  export let bookingConfig;
  export let data;

  const dispatch = createEventDispatcher();

  const date = new Date();
  const apiUrl = getHost();

  const today = {
    dayNumber: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  };

  let currentLang = 'en'; // Default language

  // Subscribe to changes in currentLanguage
  currentLanguage.subscribe(value => {
      currentLang = value;
  });

  let monthIndex = date.getMonth();

  let monthStore = [];

  let availableStore = [];

  let year = date.getFullYear();

  $: firstDayIndex = new Date(year, monthIndex, 1).getDay();

  $: firstDayIndexCal = firstDayIndex === 0 ? 7 : firstDayIndex;

  $: numberOfDays = new Date(year, monthIndex + 1, 0).getDate();

  $: calendarCellsQty = (firstDayIndex > 0 && firstDayIndex <= 5) || (firstDayIndex === 6 && numberOfDays <= 30) ? 35 : 42;

  $: selected = 0;

  $: fetchMonthsCount = 6;

  onMount(async () => {
    while (fetchMonthsCount > 0) {
      await fetchBookingDays();
      if (monthStore.length > 0) {
        fetchMonthsCount = 0;
        break;
      }
      if (monthIndex >= 11) {
        year += 1;
        monthIndex = 0;
      } else {
        monthIndex += 1;
      }
      fetchMonthsCount--;
    }
    if (monthStore.length === 0) {
      monthIndex = date.getMonth();
      year = date.getFullYear();
    }
  });

  let formatter = new Intl.DateTimeFormat(data.defaultLanguage ?? "en-US", { weekday: "short", timeZone: "UTC" });
  const days = [2, 3, 4, 5, 6, 7, 1].map((day) => {
    const dd = day < 10 ? `0${day}` : day;
    return new Date(`2017-01-${dd}T00:00:00+00:00`);
  });

  const daysList = days.map((date) => formatter.format(date));

  formatter = new Intl.DateTimeFormat(data.defaultLanguage ?? "en-US", { month: "long", timeZone: "UTC" });

  const months = Array.from({ length: 12 }, (_, i) => i + 1).map((month) => {
    const mm = month < 10 ? `0${month}` : month;
    return new Date(`2017-${mm}-01T00:00:00+00:00`);
  });

  const monthList = months.map((date) => formatter.format(date));

  $: month = monthList[monthIndex];

  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  const fetchBookingDays = async () => {
    await axios
      .get(
        `${apiUrl}/api/booking/${
          bookingConfig.id ?? 2 //for test
        }/calendar?type=month&date=${year}-${monthIndex + 1}`
      )
      .then((response) => {
        //console.log('response', response)
        monthStore = response.data.filter((item) => item.bookable).map((item) => parseInt(item.day.split("-")[2]));
        availableStore = response.data.filter((item) => item.bookable).map((item) => item.numberOfAvailableSlots);
        console.log('fetchBookingDays', monthIndex + 1, availableStore, monthStore);
      })
      .catch((error) => {
        //console.log('error', error)
      });
  };

  const fetchBookingTimes = async (i) => {
    await axios
      .get(`${apiUrl}/api/booking/${bookingConfig.id}/calendar?type=day&date=${year}-${monthIndex + 1}-${i - firstDayIndex + 1}`)
      .then((response) => {
        dispatch("click", {
          dateID: `${monthNames[monthIndex]}_${i - firstDayIndex + 1}_${year}`,
          selectedDayStore: i,
          dayStore: response.data.filter((item) => item.bookable),
        });
        selected = i;
      })
      .catch((error) => {
        //console.log('error', error)
      });
  };

  const goToNextMonth = () => {
    monthStore = [];
    if (monthIndex >= 11) {
      year += 1;
      monthIndex = 0;
    } else {
      monthIndex += 1;
    }

    console.log(monthIndex, firstDayIndex, calendarCellsQty);
    fetchBookingDays();
  };

  const goToPrevMonth = () => {
    monthStore = [];
    if (monthIndex <= 0) {
      year -= 1;
      monthIndex = 11;
    } else {
      monthIndex -= 1;
    }
    fetchBookingDays();
  };

  const handleDatePick = async (i) => {
    if (!monthStore.includes(i - firstDayIndex + 1)) {
      return;
    }
    await fetchBookingTimes(i);
  };

</script>

<div class="chatwith-booking-calendar">
  <div class="chatwith-booking-calendar-title">{data.translateSelectDateAndField ?? "Select a date and time"}</div>
  {#if fetchMonthsCount === 0}
    <div class="month">
      <ul>
        <li class="month-text">{month + " " + year}</li>
        <li class="prev" on:click={goToPrevMonth}>&#10094;</li>
        <li class="next" on:click={goToNextMonth}>&#10095;</li>
      </ul>
    </div>

    <ul class="weekdays">
      {#each daysList as item}
        <li>{item}</li>
      {/each}
    </ul>

    <ul class="days">
      {#each Array(calendarCellsQty) as _, i}
        {#if i + 1 < firstDayIndexCal || i + 1 >= numberOfDays + firstDayIndexCal}
          <li>&nbsp;</li>
        {:else if i + 1 === today.dayNumber + (firstDayIndexCal - 1) && monthIndex === today.month && year === today.year}
          <li
            class="today-point"
            class:active={monthStore.includes(i + 1 - firstDayIndexCal + 1)}
            class:selected={i + 1 === selected}
            class:last-booking-day={monthStore.includes(i + 1 - firstDayIndexCal + 1) && availableStore[monthStore.indexOf(i + 1 - firstDayIndexCal + 1)] <= 2}
            on:click={() => handleDatePick(i + 1 - (firstDayIndexCal - firstDayIndex))}
          >
            <span>{i + 1 - firstDayIndexCal + 1}</span>
            <div />
          </li>
        {:else}
          <li
            class:active={monthStore.includes(i + 1 - firstDayIndexCal + 1)}
            class:selected={i + 1 === selected}
            class:last-booking-day={monthStore.includes(i + 1 - firstDayIndexCal + 1) && availableStore[monthStore.indexOf(i + 1 - firstDayIndexCal + 1)] <= 2}
            on:click={() => handleDatePick(i + 1 - (firstDayIndexCal - firstDayIndex))}
          >
            {i + 1 - firstDayIndexCal + 1}
          </li>
        {/if}
      {/each}
    </ul>

    <div class="calendar-legend">
      <div class="calendar-legend-bullet"></div>
      <div class="calendar-legend-text">{translations.last_places_available[currentLang]}</div>
    </div>

  {:else}
    <div class="skeleton-calendar">
      <div class="month">
        <ul>
          <li class="month-box" />
          <li class="prev" on:click={goToPrevMonth}>&#10094;</li>
          <li class="next" on:click={goToNextMonth}>&#10095;</li>
        </ul>
      </div>

      <ul class="weekdays">
        {#each daysList as item}
          <li>{item}</li>
        {/each}
      </ul>

      <ul class="days">
        {#each Array(30) as _, i}
          <li>
            {i + 1}
          </li>
        {/each}
      </ul>
    </div>
  {/if}
</div>
