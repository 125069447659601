<script>
  import {createEventDispatcher} from "svelte";
  import axios from "axios";
  import ChatTextBox from "./chat/ChatTextBox.svelte";
  import ChatBubbleBot from "./chat/ChatBubbleBot.svelte";
  import ChatBubbleUser from "./chat/ChatBubbleUser.svelte";
  import NewSingleFaq from './NewSingleFaq.svelte';
  import Button from './base/Button.svelte';
  import Closer from './base/Closer.svelte';
  import {getHost} from "../utils/sendwhatsapp";
  import {v4 as uuidv4} from 'uuid';

  const uuid = uuidv4()


  export let data;
  export let activePerson;

  $: chatList = [{id: 1, botText: data.faqsChatBotQuestionText ?? 'Hello, how can I help you?', userText: ""},];
  let faqs = [];
  let isSolved = false;
  let history = []

  $: fullScreenMobile = data.expandFullScreenMobile;

  const dispatch = createEventDispatcher();

  async function setVal(v) {
    let objDiv = document.getElementById("messageBox");
    objDiv.scrollTop = objDiv.scrollHeight;
    if (v.detail.trim() == '')
      return;
    let text = v.detail;
    let param = {
      search: v.detail.trim(),
      previous:  chatList[chatList.length - 1],
    };

    param["whatsapp"] = activePerson.id;
    param["conversation"] = uuid

    await axios
    .post(getHost() + "/api/searchfaq-ai", param)
    .then((response) => {
      console.log("new")
      faqs = response.data;
      chatList[chatList.length - 1].userText = text;

      if (faqs && faqs.relevance && faqs.answer && faqs.answer.relevance !== 0) {

        chatList.push({
          id: chatList.length+ 1,
          botText: faqs.answer,
          userText: ""
        });

        isSolved = true;
      } else {
        chatList.push({
          id: chatList.length + 1,
          botText: data.faqsChatBotNotSolvedText ?? "Sorry, I don't have an answer for that, what else can I help you with?",
          userText: ""
        });
        isSolved = false;
      }

      // if (faqs.extra_question){
      //   //setTimeout(() => {
      //     chatList.push({
      //       id: chatList.length+ 1,
      //       botText: faqs.extra_question,
      //       userText: ""
      //     });
      //   //}, 1000)
      // }
      let objDiv = document.getElementById("messageBox");
      objDiv.scrollTop = objDiv.scrollHeight;

    })
    .catch((error) => {
      alert(error);
    });

  }

</script>

<div class="chatwith-chat chatwith-faqschat" class:fullScreenMobile>
  <div class="chatwith-chat-panel">
    <div class="chatwith-chat-content">
      {#if data.widgetType !== 'bigperson'}
        <Closer on:close={() => dispatch('problem-solved')}/>
      {/if}
      <div class="chatwith-chat-messages" id="messageBox">

        {#each chatList as element, i (element.id)}

          <div class="scale-in-left">
            <ChatBubbleBot text={element.botText} showDot={true}/>
          </div>
          {#if element.userText}
            <div  class="scale-in-right">
              <ChatBubbleUser bubbleData={{val:element.userText}}/>
            </div>
          {/if}
        {/each}
      </div>

      <!--{#if chatList.length > 1 && isSolved === false}-->
      <div style="height:40px"></div>
      <!--{:else}-->
        <div style="height:40px"></div>
      <!--{/if}-->
      <div class="chatwith-chat-footer">

        <ChatTextBox
        on:message={setVal}
        hasBorder={data.widgetType === "bigperson"}
        fullScreenMobile={data.expandFullScreenMobile}
        translateChatAnswer={data.translateChatAnswer}
        />

      </div>

      <div class="chatwith-faqschat-buttons">
        {#if activePerson.online === true}
          <Button
          on:click={() => dispatch('problem-not-solved')}
          buttonText="WhatsApp"
          showIcon={true}
          />
        {:else}
          <Button
          on:click={() => dispatch('leave-message')}
          buttonText={data.leaveMessage ?? "Leave a message"}
          showIcon={false}
          />
        {/if}
      </div>
    </div>
  </div>
</div>

{#if data.widgetType !== 'bigperson'}
<div class="chatwith-chat " style="position:fixed;bottom:0;left:0;right:0;height:90px;background: #F0F0F0;padding:5px">


  <div class="chatwith-chat-footer">

    <ChatTextBox
    on:message={setVal}
    hasBorder={data.widgetType === "bigperson"}
    fullScreenMobile={data.expandFullScreenMobile}
    translateChatAnswer={data.translateChatAnswer}
    />

  </div>

  <div class="chatwith-faqschat-buttons">
    {#if activePerson.online === true}
      <Button
      on:click={() => dispatch('problem-not-solved')}
      buttonText="WhatsApp"
      showIcon={true}
      />
    {:else}
      <Button
      on:click={() => dispatch('leave-message')}
      buttonText={data.leaveMessage ?? "Leave a message"}
      showIcon={false}
      />
    {/if}
  </div>

</div>
  {/if}
