import { Base64 } from 'js-base64'
import { tagging } from './tagging'
import { dataAttributes } from './getdata'
import { usedToChat } from './localStorage'
import axios, { post } from 'axios'
import { isClosedConversationStore } from '../store'

let host

function setHost(h) {
  host = h
}

function getHost() {
  return host;
}

function sendform(person, event, redirectUrl) {
  //console.log(event.detail.data)
  let str = JSON.stringify(event.detail.data)
  str = Base64.encodeURI(str)
  send(person, redirectUrl, str, event.detail?.url, event.detail.notQualified)
}

function send(person, redirectUrl, inputdata = '', buyUrl = null, notQualified = false) {
  let isClosedConversation = false;

  isClosedConversationStore.subscribe(value => {
    isClosedConversation = value;
  });

  //facbook and google tags
  tagging(person)

  //Record the user to local storage if allowed
  usedToChat()
 // Construct parameters object
  const paramObj = {
    data: inputdata,
    screenwidth: window.screen.width,
    url: window.location.href,
  }

  let extra = `&url=${encodeURIComponent(window.location.href)}`;

  // Add dataAttributes to paramObj and extra if they exist
  ['product', 'price', 'fullMessage'].forEach(attr => {
    if (dataAttributes[attr]) {
      extra += `&${attr}=${encodeURIComponent(dataAttributes[attr])}`;
      paramObj[attr] = encodeURIComponent(dataAttributes[attr]);
    }
  });

    // Add buyUrl to paramObj and extra if it exists
  if (buyUrl) {
    extra += `&buyUrl=${encodeURIComponent(buyUrl)}`;
    paramObj.buyUrl = encodeURIComponent(buyUrl);
  }

  const apiUrl = `${host}/api/business/send/${person.id}`;
  const screenWidthParam = `&screenwidth=${window.screen.width}`;

  if (!notQualified && !person.noWhatsappRedirect && !isClosedConversation) {
    window.location.href = `${apiUrl}?data=${inputdata}${extra}${screenWidthParam}`;
  } else if (redirectUrl && !isClosedConversation) {
    window.open(redirectUrl);
  } else {
    //if there is no form and not online - nothing
    axios.post(apiUrl, paramObj)
      .then(response => {
        // Handle success response
        console.log(response);
      })
      .catch(error => {
        // Handle error response
        console.error(error);
      });
  }
  isClosedConversationStore.set(false);
}

const fileUpload = async (file, whatsappId) => {
  const url = host + '/api/user-file?whatsappId=' + whatsappId
  const formData = new FormData()
  formData.append('file', file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return await post(url, formData, config)
}

const getBookingStatus = async (bookingConfigId, startTime, endTime) => {
  const url = host + '/api/booking/' + bookingConfigId + '/status'
  return await post(url, { startTime, endTime })
}

function sendBooking(person, event) {
  let isClosedConversation = false;

  isClosedConversationStore.subscribe(value => {
    isClosedConversation = value;
  });
  //facbook and google tags
  tagging(person)
  //sendBookingDataToGA()

    // Construct booking and paramObj objects
  const encodedData = Base64.encodeURI(JSON.stringify(event.detail.data));
  const booking = {
    data: encodedData,
    booking: event.detail.booking,
    url: encodeURIComponent(window.location.href),
  };

  const paramObj = {
    data: encodedData,
    booking: event.detail.booking,
    screenwidth: window.screen.width,
  };

  if (event.detail?.url) {
    const encodedUrl = encodeURIComponent(event.detail.url);
    paramObj.buyUrl = encodedUrl;
  }

  const apiUrl = `${host}/api/business/send/${person.id}`;
  const screenWidthParam = `&screenwidth=${window.screen.width}`;
  const bookingParam = `&booking=${Base64.encodeURI(JSON.stringify(booking))}`;
  const buyUrlParam = event.detail?.url ? `&buyUrl=${encodeURIComponent(event.detail.url)}` : '';

  if (person.noWhatsappRedirect || isClosedConversation) {
    //if there is no form and not online - nothing
    axios.post(apiUrl, paramObj)
      .then(response => {
        // console.log(response);
      })
      .catch(error => {
        // console.error(error);
      });
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    // Handle iOS specific logic
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    a.href = `${apiUrl}?data=${encodedData}${bookingParam}${buyUrlParam}${screenWidthParam}`;
    a.click();
    document.body.removeChild(a);
  } else {// Handle non-iOS logic
    window.open(`${apiUrl}?data=${encodedData}${bookingParam}${buyUrlParam}${screenWidthParam}`);
  }
  isClosedConversationStore.set(false);
}

function personHasFormData(person) {
  let hasForm = false
  if (person.form != null && typeof person.form === 'object') {
    hasForm =
      Object.keys(person.form).length > 0 && person.form.constructor === Object
  }
  return hasForm
}

export {
  personHasFormData,
  sendform,
  send,
  setHost,
  getHost,
  sendBooking,
  fileUpload,
  getBookingStatus,
}
