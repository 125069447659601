<script>
  import { currentLanguage } from './store.js';
  import { onMount } from "svelte";
  import BigPerson from "./widgets/BigPerson.svelte";
  import Classic from "./widgets/Classic.svelte";
  import { gData, getPersonId } from "./utils/getdata";
  import { setHost } from "./utils/sendwhatsapp";
  import { setCookies } from "./utils/localStorage";
  import { showOnlyOnce, getHue, lightOrDark } from "./utils/utilities";
  import ThemeControl from "./components/ThemeControl.svelte";
  import Notification from "./components/Notification.svelte";

  export let widgetType;
  export let injectObject;
  export let embed = false;
  export let alwaysActive = false;
  export let dataActive = false;
  export let dataHeader = false;
  export let cookieOverride;
  export let src;

  let data = {
    widgetDelay: 0,
    active: 0,
    isopen: false,
    whatsapps: [],
    faqState: "HIDE",
    activeFaqs: [],
    notMounted: true,
  };

  let personId = null;
  let person;
  let showPayment = false;
  let isExcludedSite = false;  

  onMount(async () => {
    personId = getPersonId();
    let url = "process.env.MY_HOST";
    if (src.includes("beedigital")) {
      url = "process.env.BEEDIGITAL_HOST";
    }
    setHost(url);
    data = await gData(url, injectObject);

    if (cookieOverride) {
      setCookies(false);
    } else {
      setCookies(!data.turnOffCookies);
    }

    if (widgetType == "bigperson") {
      data.widgetType = "bigperson";
    }

    if (alwaysActive) {
      data.active = true;
    }

    if (data.haltBusiness) {
      data.active = false;
    }

    if (dataActive) {
      data.active = true;
    }

    person = data.whatsapps && data.whatsapps.find((obj) => obj.isExtraLink && obj.type === "payment");

    if (person) {
      showPayment = true;
    }

    if (data.openOnce === true) {
      data.isopen = showOnlyOnce(data, widgetType);
    }
    if (data.widgetDelay > 0 && data.active) {
      //console.log(data.widgetDelay, data.active);
      data.active = false;
      setTimeout(() => (data.active = true), data.widgetDelay * 1000);
    }
    
    if (data.excludeUrls) {
      for (const pattern of data.excludeUrls) {
        // Escape any special characters in the pattern and replace placeholders
        const escapedPattern = pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      // Convert '*' to a regular expression wildcard '.*'
        const wildcardPattern = escapedPattern.replace(/\\\*/g, '.*');
        // Create a regular expression from the pattern
        const regex = new RegExp(`${wildcardPattern}$`);
        // Check if the current URL matches the pattern
        let path = window.location.origin + window.location.pathname;
        if (path[path.length - 1] === '/') {
          path = path.slice(0, -1);
        }
        if (regex.test(path) || regex.test(path + "/")) {
          isExcludedSite = true;
          break;
        }
      }
    }

  });

  async function fetchUserLocation() {
    try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
            throw new Error('Failed to fetch IP address');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return null;
    }
  }

  async function detectLanguageFromIP() {
      const locationData = await fetchUserLocation();

      if (locationData && locationData.country_code) {
          // Map country code to language
          switch (locationData.country_code.toLowerCase()) {
              case 'fr':
                  return 'fr'; // French
              case 'es':
                  return 'es'; // Spanish
              default:
                  return 'en'; // Default to English or your preferred fallback
          }
      }

      return 'en'; // Fallback to default language
  }

  async function switchToUserLanguage() {
      const detectedLanguage = await detectLanguageFromIP();
      currentLanguage.set(detectedLanguage);
  }

  switchToUserLanguage(); // Automatically switch to user's language on app load
</script>

{#if !data.notMounted && data.active && !isExcludedSite}
  <div
    class="chatwithapp"
    class:chatwithrtl={data.rtl === true}
    style="
    --color:{data.color};
    --grColor:{data.color}50;
    --textColor:{data.textColor};
    --thumbColor:hsl({getHue(data.color)}deg 100% 30%);
    --thumbBgColor:hsl({getHue(data.color)}deg 100% 60%);
    --fgColor:{lightOrDark(data.color)};
    --personColor:{data.noHeader ? data.color : '#fff'};
    --buttonRounding:{data.buttonRounding}px;
    --rounding:{data.rounding}px;"
  >
    {#if widgetType == "bigperson"}
      <div class="chatwithbigperson">
        <Notification popupInfo={data.businessPopUpNotification} />
        {#if showPayment}
          <BigPerson {person} {data} {dataHeader} />
        {:else}
          {#each data.whatsapps as person, i}
            {#if personId === null || personId == person.id}
              <BigPerson {person} {data} {dataHeader} />
            {/if}
          {/each}
        {/if}
      </div>
    {/if}

    {#if widgetType == "classic"}
      <div>
        <Classic openWidget={data.isopen} {data} embed={data.expandFullScreenMobile ? false : embed} />
      </div>
    {/if}
  </div>
{/if}

<ThemeControl {data} />

<!-- 
<svelte:head>
  <style>
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
  </style>
</svelte:head> -->
<style>
  .chatwithrtl {
    direction: rtl;
    text-align: initial;
  }
  .chatwithbigperson {
    display: flex;
    justify-content: center;
    flex-flow: wrap row;
    margin-left: -1em;
    margin-right: -1em;
    row-gap: 40px;
  }
  @media only screen and (max-width: 420px) {
    .chatwithbigperson {
      margin: 0;
    }
  }
</style>
