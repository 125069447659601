<script>
  import axios from "axios";
  import { getLocalStorage } from "../utils/localStorage";
  import TelField from "./base/TelField.svelte";
  import Button from "./base/Button.svelte";
  import Typography from "./base/Typography.svelte";
  import { getHost } from "../utils/sendwhatsapp";

  export let person;
  export let data;

  const personId = `${person.id}leaveMessage`;
  const personForm = getLocalStorage(personId);

  $: fullScreenMobile = data.expandFullScreenMobile;

  let num = personForm && personForm.num ? personForm.num : null;
  let code = data.dialingCode;
  let showSuccess = false;
  let showFail = false;
  let err = false;

  function send() {
    if (!num) {
      err = data.requiredValidationText ? data.requiredValidationText : "This field is required";
      return;
    } else if (!/^\d{6,15}$/.test(num) || (code === '34' && !/^[6-8]\d{8}$/.test(num) && !/^[9]\d{8,9}$/.test(num))) {
      err = data.telValidationText ? data.telValidationText : "This is not a phone number.";
      return;
    } else {
      err = false;
    }

    let fullNumber = code.toString() + num.toString();

    axios
      .post(getHost() + "/api/callback", {
        id: person.id,
        number: fullNumber,
      })
      .then(() => {
        showSuccess = true;
        showFail = false;
      })
      .catch((error) => {
        //console.log(error);
        showSuccess = false;
        showFail = true;
      });
  }

  const setVal = (v) => {
    code = v.detail.code.trim();
    num = v.detail.num.trim();
  };
</script>

<div class="chatwith-leavemessage" class:fullScreenMobile>
  <Typography
    className="m-10"
    text={data.offlineMessage ?? "This operator is not online at the moment.\n Please leave your telephone number and we'll get back"}
  />

  {#if showSuccess}
    <Typography text="Your message has been created" className="chatwith-leavemessage-msg" success={true} />
  {/if}

  {#if showFail}
    <Typography text="There has been an error, Please contact support" className="chatwith-leavemessage-msg" fail={true} />
  {/if}

  <TelField {data} on:message={setVal} class="chatwith-outer-telfield" />
  {#if err}<div class="chatwith-base-error-text">{err}</div>{/if}
  <Button id={"chatwith-leavemessage-button"} on:click={send} buttonText={data.leaveMessage ?? "Leave a message"} showIcon={false} />
</div>
