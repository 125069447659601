<script>
  import { fade } from 'svelte/transition'
  import { onMount } from "svelte";

  export let person;
  export let data;
  export let isGrid = false;
  export let isInnerPeople = false;

  let container;
  let personDiv;
  let showTooltip = false;

  $: fullScreenMobile = data.expandFullScreenMobile;
  $: gridView = isGrid;
  $: innerPeople = isInnerPeople;
  $: leftpos = !data.rightpos;

  onMount(() => {
    if (person.isExtraLink && person.type != "payment") {
      const child = document.createElement("a");
      child.target = person.type == "link" ? "_blank" : "_self";
      if (person.type == "link") {
        child.href = person.url;
      } else if (person.type == "email") {
        child.href = "mailto:" + person.url;
      }else if (person.type == "imessage") {
        child.href = "imessage://"+ person.url+ "&body=";
      } else {
        child.href = person.type + ":" + person.url;
      }
      child.style["text-decoration"] = "none";
      child.append(personDiv);
      container.append(child);
    }
  });

  const handleMouseOver = () => {
    if(data.widgetTheme === "dotTheme" && isInnerPeople) {
      showTooltip = true;
    }
  }

  const handleMouseOut = () => {
    showTooltip = false;
  }
</script>

<div
  id={"chatwith-agent-" + person.id}
  bind:this={container}
  class="chatwith-person"
  class:fullScreenMobile
  class:innerPeople
  class:gridView  
  class:leftpos
>
  <div bind:this={personDiv}>
    <div
      class="chatwith-singleperson {person.online === true
        ? 'chatwith-active'
        : 'chatwith-inactive'}"
    >
      <div class="chatwith-avatar" on:mouseover={handleMouseOver} on:mouseout={handleMouseOut}>
        {#if data.widgetTheme !== "roundedTheme"}
        <img
          class="chatwith-avatar-pic"
          alt=""
          src={person.iconUrl}
          onerror="this.src='process.env.MY_HOST'+'/bk.png'"
        />
        <span class="chatwith-circle" />
        {/if}
      </div>
      {#if showTooltip}
      <div class="chatwith-person-tooltip"  in:fade={{ delay: 20 }}>
        <div class="chatwith-name-box"> 
          {#if person.post && person.post != "null" && person.post != null}
            <div class="chatwith-post">{person.post}</div>
          {/if}
          <div class="chatwith-name">{person.name}</div>
        </div>
        {#if !person.isExtraLink}
          <div class="chatwith-status chatwith-online">
            <span class="chatwith-circle"/>
            {#if person.online === true} Online{:else} Offline{/if}
          </div>
        {:else if person.type == "payment"}
          <div class="chatwith-status chatwith-online">
            <span class="chatwith-circle" />
            Buy Online
          </div>
        {/if}
      </div>
      {/if}
      {#if data.widgetTheme !== "dotTheme" || !isInnerPeople}
      <div class="chatwith-persondata">
        {#if person.post && person.post != "null" && person.post != null}
          <div class="chatwith-post">{person.post}</div>
        {/if}
        <div class="chatwith-name">{person.name}</div>
        {#if !person.isExtraLink}
          <div class="chatwith-status chatwith-online">
            <span class="chatwith-circle"/>
            {#if person.online === true} Online{:else} Offline{/if}
          </div>
        {:else if person.type == "payment"}
          <div class="chatwith-status chatwith-online">
            <span class="chatwith-circle" />
            Buy Online
          </div>
        {/if}
      </div>
      {/if}
    </div>
  </div>
</div>

