<script>
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  export let buttonText;
  export let showIcon = false;
  export let iconUrl = '';
  export let className = '';
  export let spanClassName = '';
  export let imgClassName = '';
  export let id = '';
</script>

<div id={id} class="chatwith-button {className}" on:click={() => dispatch('click')} >
  <span class={spanClassName}>{buttonText}</span>
  {#if showIcon}
  <img
    class="chatwith-img {imgClassName}"
    alt="tochat-whatsapp"
    src={iconUrl?iconUrl:("process.env.CDN_HOST" + "/icns/gallery/whatsapps/whatsapp-icon.png")}
  />
  {/if}
</div>
