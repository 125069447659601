<script>
  import {onMount} from "svelte";
  import {fade} from "svelte/transition";
  import Mainbutton from "../components/Mainbutton.svelte";
  import Online from "../components/Online.svelte";
  import Form from "../components/Form.svelte";
  import {personHasFormData, send, sendform} from "../utils/sendwhatsapp";
  import Modal from "../components/Modal.svelte";
  import {tick} from "svelte";
  import Chat from "../components/Chat.svelte";
  import Payment from "../components/Payment.svelte";
  import Faqs from "../components/Faqs.svelte";
  import NewFaqs from "../components/NewFaqs.svelte";
  import FaqsChat from "../components/FaqsChat.svelte";
  import LeaveMessage from "../components/LeaveMessage.svelte";
  import Booking from "../components/booking/Booking.svelte";
  import {sendBooking} from "../utils/sendwhatsapp";
  import WidgetHeader from "../components/WidgetHeader.svelte";
  import CountDown from "../components/CountDown.svelte";
  import FaqsChatAi from "../components/FaqsChatAi.svelte";

  export let person;
  export let data;
  export let dataHeader;

  $: hideAvatar = person?.hideAvatar;

  let showfaqs = false;
  let showpayment = person.isExtraLink && person.type == "payment";
  let formitems = {items: []};
  let isOpen = false;
  let isOpen2 = false;

  let noclose = true;

  let valueCopy = `<script defer data-widget-type="group" data-person="${person.id}"     src=\'/build/bundle.js?key=${data.id}\'><\/script><div id="whatapp-people-widget"><\/div>`;
  let areaDom;
  let faqlevel;

  let container;
  let wrapperDiv;
  let leaveamessage = false;
  let showbooking = false;
  let bookingConfig = {};
  let bookingEvent = null;

  onMount(() => {
    if (person.isExtraLink && person.type != "payment") {
      const child = document.createElement("a");
      child.target = person.type == "link" ? "_blank" : "_self";
      child.href = (person.type == "link" ? "" : (person.type == "email" ? "mailto" : person.type) + ":") + person.url;
      child.style["text-decoration"] = "none";
      child.append(wrapperDiv);
      container.append(child);
    }
    if (person?.bookingConfigs?.length > 0) {
      bookingConfig = person?.bookingConfigs[0];
      showbooking = true;
    }
  });

  if (person?.faqGrps?.length > 0) {
    data.activeFaqs = person.faqGrps[0];
    showfaqs = true;
    faqlevel = "whatsapp";
  } else if (data.faqGrps?.length > 0) {
    data.activeFaqs = data.faqGrps[0];
    showfaqs = true;
    faqlevel = "business";
  } else {
    showfaqs = false;
  }

  async function copy() {
    await tick();
    areaDom.focus();
    areaDom.select();
    try {
      const successful = document.execCommand("copy");
      if (successful) {
        alert("Text copied to clipboard");
      }
    } catch (err) {
      //console.log("could not copy to clipbloard");
    }
  }

  if (person.form) {
    formitems = person.form;
  }

  function close() {
    showform = false;
  }

  function sf(event) {
    sendform(person, event, data.redirectUrl);
  }

  function handleClick(event) {
    //if there is no form and not online - nothing
    leaveamessage = false;
    if (!person.form && ((person.online === false && data.offlineLeads === true) || (data.desktopLeads === true && window.innerWidth > 1000))) {
      leaveamessage = true;
      return;
    }
    if (person.form) {
      showform = true;
    } else {
      if (person.isExtraLink && person.type != "payment") {
        return;
      }
      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
        return;
      }
      send(person, data.redirectUrl, event);
    }
  }

  function problemSolved() {
  }

  function problemNotSolved() {
    const hasForm = personHasFormData(person);
    if (hasForm === false && person.online === false) {
      return;
    }
    //send the whatsapp
    else if (hasForm === false) {
      if (data.redirectUrl) {
        window.open(data.redirectUrl);
        return;
      }
      send(person, data.redirectUrl, {});
    } else {
      showfaqs = false;
    }
  }

  const handleBooking = (event) => {
    if (person?.onboardPaymentLinks?.length > 0) {
      person.onboardPaymentLinks.forEach((value) => {
        if (value.showOnWidget && value.useStripe) {
          person = {
            name: value.name,
            iconUrl: value.image,
            online: true,
            isExtraLink: true,
            type: "payment",
            id: value.link,
            bookingConfigs: person?.bookingConfigs,
          };
          if (!data.onboardPaymentLinks) {
            data.onboardPaymentLinks = [];
          }
          data.onboardPaymentLinks.push(value);
          showpayment = true;
          bookingEvent = event;
        } else {
          sendBooking(person, event);
        }
      });
    } else {
      sendBooking(person, event);
    }
  };
</script>

<div id={"chatwith-big-agent-" + person.id} class="chatwith-big-person" class:hideAvatar>
  <div class="singleperson {person.online === true ? 'active' : 'inactive'}" style="padding: {dataHeader ? 0 : '1.25em 1.25em 20px'}">
    {#if dataHeader}
      <WidgetHeader hideClose={true} {data} {dataHeader}/>
    {/if}
    <CountDown {data} hasBanner={true}/>
    <div class="avatar">
      <div class="image-wrapper">
        <img class="avatar-pic" alt="" src={person.iconUrl}/>
      </div>
      <Online {person}/>
    </div>
    <div class="chatwith-big-person-persondata">
      {#if !dataHeader && data.whatsapps.length == 1}
        <div class="chatwith-big-person-message">{data.widgetMessage}</div>
      {/if}

      {#if person.post && person.post != "null" && person.post != null}
        <div class="chatwith-big-person-post">{person.post}&nbsp;</div>
      {/if}
      <div class="chatwith-big-person-name"><strong>{person.name}</strong></div>

      <Modal {isOpen}>
        <div style="font-size: 14px;margin: 10px;font-weight: bold">Copy and paste this code into your website</div>
        <textarea on:click={copy} bind:this={areaDom} style="font-size: 14px;color: #999" rows="7">
          {valueCopy}
        </textarea>
      </Modal>

      <Modal isOpen={isOpen2}>
        <div style="font-weight: bold;font-size: 14px; padding-bottom:10px">
          {data.translateOnlineFrom ?? "I'm online from"}:
        </div>
        <table style="font-size: 14px">
          {#each person.optimes as i}
            <tr>
              <td style="padding: 2px;text-align: left">{i.day}</td>
              <td style="padding: 2px;text-align: left">{i.availableFrom.slice(0, -3)}</td>
              <td style="padding: 2px;text-align: left"> {i.availableUntil.slice(0, -3)}</td>
              <td style="color:#999;padding: 2px;text-align: left">
                {#if i.timezone}{i.timezone}{/if}
              </td>
            </tr>
          {/each}
        </table>
      </Modal>
    </div>
    {#if showpayment}
      <Payment {data} {bookingEvent} {person} {noclose}/>
    {:else if formitems.items.length == 0 && !showfaqs}
      <div class="widget-button-wrapper" bind:this={container}>
        <div bind:this={wrapperDiv}>
          <Mainbutton on:close={handleClick} {data} widgetType="BigPerson"/>
        </div>
      </div>
    {/if}
    <div class="copy-widget-wrapper">
      <span on:click={() => (isOpen = Math.random())}>
        <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="far"
        data-icon="copy"
        class="svg-inline--fa fa-copy fa-w-14"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        ><path
        fill="currentColor"
        d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"
        /></svg
        >
        Copy widget
      </span>
    </div>
    {#if person.optimes && person.optimes.length > 0}
      <div class="timetable">
        <span on:click={() => (isOpen2 = Math.random())}>
          <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="user-clock"
          class="svg-inline--fa fa-user-clock fa-w-20"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
          ><path
          fill="currentColor"
          d="M496 224c-79.6 0-144 64.4-144 144s64.4 144 144 144 144-64.4 144-144-64.4-144-144-144zm64 150.3c0 5.3-4.4 9.7-9.7 9.7h-60.6c-5.3 0-9.7-4.4-9.7-9.7v-76.6c0-5.3 4.4-9.7 9.7-9.7h12.6c5.3 0 9.7 4.4 9.7 9.7V352h38.3c5.3 0 9.7 4.4 9.7 9.7v12.6zM320 368c0-27.8 6.7-54.1 18.2-77.5-8-1.5-16.2-2.5-24.6-2.5h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h347.1c-45.3-31.9-75.1-84.5-75.1-144zm-96-112c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128z"
          /></svg
          >
          {data.translateShowTimetable ?? "Show timetable"}
        </span>
      </div>
    {/if}
    {#if !showpayment}
      {#if leaveamessage === true}
        <div style="padding:10px">
          <LeaveMessage {data} {person}/>
        </div>
      {:else if showfaqs}
        <div class="chat-container" in:fade>
          {#if person.aiChatFaq === true}
            <FaqsChatAi on:problem-solved={problemSolved} on:problem-not-solved={problemNotSolved} activePerson={person} {data}/>
          {:else if data.aiChatFaq === true}
            <FaqsChat on:problem-solved={problemSolved} on:problem-not-solved={problemNotSolved} activePerson={person} {faqlevel} {data}/>
          {:else if data.faqDisplay === true}
            <NewFaqs on:problem-solved={problemSolved} on:problem-not-solved={problemNotSolved} faqs={data.activeFaqs} activePerson={person}
                     {data}/>
          {:else}
            <Faqs on:problem-solved={problemSolved} on:problem-not-solved={problemNotSolved} faqs={data.activeFaqs} activePerson={person} {data}/>
          {/if}
        </div>
      {:else if formitems.items.length > 0}
        {#if person.chatform && person?.bookingConfigs?.length === 0}
          <div class="chat-container">
            <div class="chat-container-box">
              <Chat {data} activePerson={person} on:callmex={sf} isBigperson={true}/>
            </div>
          </div>
        {:else if showbooking}
          <Booking on:close={() => (showbooking = false)} on:submit={handleBooking} {person} {bookingConfig} noclose={true} {data}/>
        {:else}
          <Form on:callmex={sf} on:close={close} on:booking={() => (showbooking = true)} {data} {person} {formitems} {noclose}
                isBigperson={true}/>
        {/if}
      {/if}
    {/if}
  </div>
</div>
